import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import AppContext, { IAppContext } from "app/App/context";
import { FC, useContext, useEffect, useRef, useState } from "react";

interface IMap {
    lat?: number | string;
    lng?: number | string;
    zoom?: number;
    markerProps?: any;
    options?: any;
    containerStyle?: any;
    children?: any;
    data?: any;
    defaultOptions?: any;
}

const MapComponent: FC<IMap> = (props) => {
    const {
        children,
        data,
        lat,
        lng,
        options,
        zoom,
        markerProps,
        containerStyle,
        ...restProps
    } = props;
    const center = { lat: lat ? +lat : 59.911491, lng: lng ? +lng : 10.757933 };
    const mapRef = useRef<any>(null);
    const [map, setMap] = useState<any>(null);
    const context = useContext(AppContext) as IAppContext;

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    });

    useEffect(() => {
        const updateMapBounds = async () => {
            if (isLoaded && mapRef.current && data && data.length > 0) {
                let bounds = new window.google.maps.LatLngBounds();
                const extendBounds = function () {
                    const extendBy = context.isMediumScreen ? 0.001 : 0.0015;
                    const point1 = new google.maps.LatLng(
                        bounds.getNorthEast().lat() + extendBy,
                        bounds.getNorthEast().lng() + extendBy
                    );
                    const point2 = new google.maps.LatLng(
                        bounds.getSouthWest().lat() - extendBy,
                        bounds.getSouthWest().lng() - extendBy
                    );
                    bounds.extend(point1);
                    bounds.extend(point2);
                };
                data.forEach((item: any) => {
                    if (item.lat && item.lng) {
                        bounds.extend(
                            new window.google.maps.LatLng(+item.lat, +item.lng)
                        );
                    } else if (item.lat1 && item.lng1) {
                        bounds.extend(
                            new window.google.maps.LatLng(
                                +item.lat1,
                                +item.lng1
                            )
                        );
                    }
                });

                if (!bounds.isEmpty()) {
                    extendBounds();
                    map.fitBounds(bounds);
                }
            }
        };
        updateMapBounds();
    }, [isLoaded, data, map, context.isMediumScreen]);

    useEffect(() => {
        const resizeListener = () => {
            if (map && mapRef.current && data && data.length > 0) {
                let bounds = new window.google.maps.LatLngBounds();
                const extendBounds = function () {
                    const extendBy = context.isMediumScreen ? 0.001 : 0.0015;
                    const point1 = new google.maps.LatLng(
                        bounds.getNorthEast().lat() + extendBy,
                        bounds.getNorthEast().lng() + extendBy
                    );
                    const point2 = new google.maps.LatLng(
                        bounds.getSouthWest().lat() - extendBy,
                        bounds.getSouthWest().lng() - extendBy
                    );
                    bounds.extend(point1);
                    bounds.extend(point2);
                };
                data.forEach((item: any) => {
                    if (item.lat && item.lng) {
                        bounds.extend(
                            new window.google.maps.LatLng(+item.lat, +item.lng)
                        );
                    } else if (item.lat1 && item.lng1) {
                        bounds.extend(
                            new window.google.maps.LatLng(
                                +item.lat1,
                                +item.lng1
                            )
                        );
                    }
                });

                if (!bounds.isEmpty()) {
                    extendBounds();
                    if (data.length > 1) {
                        map.fitBounds(bounds);
                    }
                }
            }
        };

        if (map) {
            window.addEventListener("resize", resizeListener);
        }

        return () => {
            if (map) {
                window.removeEventListener("resize", resizeListener);
            }
        };
    }, [map, data, context.isMediumScreen]);

    const handleMapLoad = (mapInstance: any) => {
        mapRef.current = mapInstance;
        setMap(mapInstance);
    };

    return isLoaded ? (
        <GoogleMap
            onLoad={handleMapLoad}
            mapContainerStyle={{
                width: "100%",
                minHeight: "250px",
                height: "320px",
                ...containerStyle,
            }}
            {...(!data && { center })}
            zoom={zoom ? zoom : 10}
            options={options}
            {...restProps}
        >
            {children ? (
                children
            ) : (
                <Marker position={center} {...markerProps} />
            )}
        </GoogleMap>
    ) : (
        <></>
    );
};

export default MapComponent;
