import { getColorByBgColor } from "helpers/colorShades";
import { FC } from "react";
import { IIcon } from "ui/types";

const ArrowRightSolidIcon: FC<IIcon> = ({ color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
        >
            <path
                d="M33 18C33 9.7155 26.2845 3 18 3C9.7155 3 3 9.7155 3 18C3 26.2845 9.7155 33 18 33C26.2845 33 33 26.2845 33 18Z"
                fill={getColorByBgColor(color)}
            />
            <mask
                id="mask0_122_35176"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="2"
                width="32"
                height="32"
            >
                <path
                    d="M33 18C33 9.7155 26.2845 3 18 3C9.7155 3 3 9.7155 3 18C3 26.2845 9.7155 33 18 33C26.2845 33 33 26.2845 33 18Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.75 11.25L22.5 18L15.75 24.75"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </mask>
            <g mask="url(#mask0_122_35176)">
                <path
                    d="M0 36L4.29296e-07 0L36 4.29296e-07L36 36L0 36Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default ArrowRightSolidIcon;
