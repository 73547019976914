import {
    appSettings as appSettingsRequest,
    appTranslate,
    changeLocale,
} from "./actions";
import api from "./api";

import { call, put, takeLatest } from "@redux-saga/core/effects";
import { request } from "api/apiSaga";
import { entries, map } from "lodash";
import { loadModules } from "moduleSaga";
import { AnyAction } from "redux";
import {
    APPEND_PARAMS,
    APP_INIT,
    APP_LOADED,
    APP_TRANSLATE_REQUEST,
    LOCALE_REQUEST,
} from "types";
import queryString from "query-string";
import moment from "moment";
import { timeRange } from "helpers/timeRange";

function* initApp() {
    const pathname = window.location.pathname;
    const regex = /\/p\/(\w+)/;

    const match = pathname.match(regex);
    const group = match && match[1];

    const searchParams = window.location.search && window.location.search;
    localStorage.setItem("params", searchParams);

    // @ts-ignore
    yield call(appParams, { groupId: group, page: 1 });

    // @ts-ignore
    const settings = yield call(
        appSettings,
        appSettingsRequest({
            params: {
                group_id: group,
                _sort: { position: "asc" },
                _with: ["subtranslation"],
            },
        })
    );
    yield call(translate, appTranslate());
    //register settings
    yield call(loadModules, settings.data);
    yield put({
        type: APP_LOADED,
    });
}

export function* appSettings(action: AnyAction): any {
    return yield call(request, api.appSettings, action);
}
export function* appParams({
    groupId,
    page,
}: {
    groupId: string;
    page: number;
}): any {
    const paramsFromStorage: any = localStorage.getItem("params");

    const filters: any = {};

    const paramsObject = queryString.parse(paramsFromStorage, {
        arrayFormat: "bracket",
    });

    entries(paramsObject).forEach(([key, value]) => {
        filters[key as keyof typeof filters] = value;
        if (
            key === "cuisine" ||
            key === "dishes" ||
            key === "award_winning" ||
            key === "accessibility" ||
            key === "price" ||
            key === "seating_options" ||
            key === "type"
        ) {
            filters[key as keyof typeof filters] = map(value, Number);
        }
        const currentDate = moment();
        if (key === "date") {
            const paramsDate = moment(value as string, "YYYY-MM-DD");
            filters["date"] = paramsDate.isBefore(currentDate)
                ? currentDate.format("YYYY-MM-DD")
                : value;
        }
        if (key === "time") {
            const providedTime = moment(value as string, "HH:mm");
            const currentTime = moment();
            const paramsDate = filters["date"]
                ? moment(filters["date"] as string, "YYYY-MM-DD")
                : null;
            const timeToUse =
                providedTime.isBefore(currentTime) &&
                (paramsDate?.isSame(currentDate, "day") || !paramsDate)
                    ? currentTime
                    : providedTime;
            let updatedTime = moment.max(timeToUse, moment("06:00", "HH:mm"));
            if (updatedTime.isSameOrAfter(moment("23:46", "HH:mm"))) {
                updatedTime = moment("23:45", "HH:mm");
            } else if (paramsDate?.isSame(currentDate, "day") || !paramsDate) {
                updatedTime = moment(updatedTime)
                    .startOf("hour")
                    .add(Math.ceil(updatedTime.minute() / 15) * 15, "minutes");
            }

            filters["time"] = updatedTime.format("HH:mm");
            filters["time_from"] = timeRange(
                updatedTime.format("HH:mm")
            ).timeFrom;
            filters["time_to"] = timeRange(updatedTime.format("HH:mm")).timeTo;
        }
    });

    yield put({
        type: APPEND_PARAMS,
        urlParams: { page, group_id: groupId },
        filters,
    });
}

function* translate(action: AnyAction): any {
    const response = yield call(request, api.translate, action);

    if (response.status === 200) {
        (window as any).translate = response.data.data;
        document.documentElement.lang = response.data.locale;
    }
}
function* locale(action: AnyAction): any {
    yield call(translate, changeLocale(action.axiosConfig.config));
}
export const appSagas = [
    takeLatest(APP_INIT, initApp),
    takeLatest(APP_TRANSLATE_REQUEST, translate),
    takeLatest(LOCALE_REQUEST, locale),
];
