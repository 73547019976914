import React, { FocusEventHandler, forwardRef, useContext, useState } from "react";
import { getHelperText, getValue } from "./helpers";
import FormContext from "./FormContext";
import { IGPTextField } from "./TextField";

export interface IGPNumberField extends Omit<IGPTextField, "value"> {
    precision?: number;
    value?: number | string;
    min?: number;
    max?: number;
    onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
}

const GPTextField = forwardRef<any, IGPNumberField>((props, ref) => {
    const {
        errorText,
        fullWidth,
        id,
        label,
        onChange,
        startAdornment,
        className,
        value,
        onBlur,
        min,
        max,
        precision = 0,
        helperText,
    } = props;
    const context = useContext(FormContext);
    const inputValue = getValue(id, context, value);

    const [val, setVal] = useState(
        inputValue || inputValue === 0
            ? (+inputValue + 0.0).toFixed(precision)
            : ""
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = e.target.value.replace(",", ".");

        const regex = new RegExp(`^\\d{0,16}([.]\\d{0,${precision}})?$`);
        if (targetValue.length === 0 || regex.test(targetValue)) {
            const intValue = parseFloat(targetValue);

            if (!!onChange) {
                onChange({
                    id,
                    value: intValue,
                });
            } else {
                context?.onChange({
                    id,
                    value: intValue,
                });
            }

            setVal(targetValue);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!!onBlur) {
            onBlur(e);
        }

        let floatNumber = "";
        if (val !== "") {
            floatNumber = (+val + 0.0).toFixed(precision);
        }

        setVal(floatNumber);
    };
    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );
    return (
        <div {...(className ? { className } : {})}>
            <label className="block text-lg font-medium leading-6 tracking-tighter">
                {label}
            </label>
            <div
                className={`relative rounded shadow-sm inline-flex items-center mt-0 ${
                    fullWidth ? "w-full" : "w-52"
                }`}
            >
                <span className="absolute left-0 h-6 ml-2 top-1/4">
                    {startAdornment && startAdornment}
                </span>
                <input
                    min={min ? min : 0}
                    max={max}
                    type="number"
                    name={id}
                    id={id}
                    className={`${
                        startAdornment ? "pl-10 " : "pl-2"
                    } block w-full h-12 rounded border-0 py-1.5 pr-4  text-Gray-70 placeholder:text-Gray-40 text-base leading-6 focus:outline-none ${
                        isError && `border-[1px] border-red-700 focus:border-2`
                    }`}
                    onChange={handleChange}
                    value={val}
                    onBlur={handleBlur}
                />
            </div>
            {inputHelperText && (
                <span
                    className={`text-white h-5 w-full block text-sm ${
                        isError ? "text-red-700" : ""
                    }`}
                >
                    {inputHelperText}
                </span>
            )}
            <span className="block w-full h-5 text-sm text-red-700">
                {isError && errorText}
            </span>
        </div>
    );
});

GPTextField.defaultProps = {
    errorText: "",
    helperText: "",
};

export default GPTextField;
