import { FC, ReactNode, useContext, useEffect, useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { getValue } from "./helpers";
import trans from "helpers/trans";
import FormContext from "./FormContext";
import { useDispatch, useSelector } from "react-redux";
import { adjust } from "helpers/colorShades";
import { POPOVER_OPEN } from "types";
import useFontForGroup from "hooks/useFontForGroup";
interface IDatePickerInput {
    id: string;
    inputClassName?: string;
    containerClassName?: string;
    toggleClassName?: string;
    errorText?: string;
    formControlProps?: object;
    helperText?: string;
    labelProps?: object;
    multiple?: boolean;
    onChange?: Function;
    children?: ReactNode;
    value?: string | number;
    className?: string;
    fullWidth?: boolean;
    startAdornment?: ReactNode;
    minDate?: Date;
    onClose?: Function;
    placeholder?: string;
}

const DatePickerInput: FC<IDatePickerInput> = (props) => {
    const {
        children,
        errorText,
        helperText,
        id,
        onChange,
        value,
        className,
        startAdornment,
        fullWidth,
        onClose,
        placeholder,
        ...restProps
    } = props;
    const context = useContext(FormContext);
    const dispatch = useDispatch();
    const { pax, time, date } = useSelector((state: any) => state.app.filters);
    const raleway = useFontForGroup()

    const [updateAfterDelete, setUpdateAfterDelete] = useState<number>(0);
    const { button_border_radius, background } = useSelector(
        (state: any) => state.app.settings.data
    );
    const { deleteFilters, showOptions } = useSelector(
        (state: any) => state.app
    );

    const prepareElements = () => {
        const container = document.querySelector("div.date-container");
        const dateContainer = document.querySelector("div.date-container>div");
        const popoverContent = document.querySelector("div.popover-content");
        if (container) {
            const isVisible = dateContainer?.classList.contains("opacity-1");

            const blockDiv = container.querySelector(
                "div.block div:nth-child(2)"
            );
            const containerDiv = document.createElement("div");
            containerDiv.classList.add("title-container");

            const titleDiv = document.createElement("div");
            titleDiv.textContent = trans("ui.form.datePicker.title");
            titleDiv.classList.add("date-title");
            titleDiv.style.fontFamily = raleway ? "Raleway, sans-serif" : "Inter, sans-serif"
            const containerButton = document.createElement("div");
            containerButton.classList.add("button-container");

            const closeButton = document.createElement("button");
            closeButton.classList.add("close-date");
            closeButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>`;

            const deleteButton = document.createElement("button");
            deleteButton.classList.add("delete-date");
            deleteButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
          </svg>
          `;

            const titleContainer = container.querySelector(".title-container");

            if (!titleContainer) {
                containerDiv.appendChild(titleDiv);
                containerDiv.appendChild(containerButton);
                containerButton.appendChild(deleteButton);
                containerButton.appendChild(closeButton);
                blockDiv?.insertBefore(containerDiv, blockDiv.firstChild);
            }

            if (isVisible) {
                container.classList.add("visible");
                document.body.style.overflow = "hidden";
                dispatch({ type: POPOVER_OPEN, payload: true });
            } else {
                container.classList.remove("visible");
                if (popoverContent) return;
                else document.body.style.overflow = "";
            }
        }
    };
    const handleClassLists = () => {
        const dateContainer = document.querySelector("div.date-container>div");
        if (dateContainer) {
            dateContainer.classList.remove("opacity-0");
            dateContainer.classList.add("opacity-1");
            dateContainer.classList.add("block");
            dateContainer.classList.remove("hidden");
        }
    };
    useEffect(() => {
        if (deleteFilters === "time" || deleteFilters === "pax")
            setUpdateAfterDelete((prev) => prev + 1);
    }, [deleteFilters]);

    useEffect(() => {
        const dateInput: any = document.querySelector(".date-container input");
        if (dateInput) {
            const buttonBorder = adjust(background, -66);
            dateInput.style.borderColor = buttonBorder;
            dateInput.style.borderRadius =
                button_border_radius === "round" ? "40px" : "unset";
        }
    }, [background, button_border_radius, updateAfterDelete]);

    useEffect(() => {
        if (!date && (pax || time) && showOptions) {
            handleClassLists();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, time, pax, showOptions]);

    useEffect(() => {
        prepareElements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const container = document.querySelector("div.date-container");

        if (container) {
            const closeButton = container.querySelector(".close-date");
            const deleteButton = container.querySelector(".delete-date");

            if (closeButton) {
                closeButton.addEventListener("click", handleCancelButtonClick);
            }
            if (deleteButton) {
                deleteButton.addEventListener("click", handleDeleteDate);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const container = document.querySelector(
                "div.date-container>div.transition-all.block"
            );
            const input = document.querySelector("div.date-container>input");
            if (
                container &&
                !container.contains(event.target as Node) &&
                input &&
                !input.contains(event.target as Node)
            ) {
                setTimeout(() => {
                    dispatch({ type: POPOVER_OPEN, payload: false });
                    if (showOptions) dispatch({ type: "SHOW_OPTIONS" });
                }, 10);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOptions]);

    useEffect(() => {
        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                const container = document.querySelector("div.date-container");
                if (
                    mutation.type === "attributes" &&
                    mutation.attributeName === "class"
                ) {
                    if (container) {
                        prepareElements();
                        const closeDateButton =
                            container.querySelector(".close-date");
                        const deleteDateButton =
                            container.querySelector(".delete-date");
                        if (closeDateButton) {
                            closeDateButton.addEventListener(
                                "click",
                                handleCancelButtonClick
                            );
                        }

                        if (deleteDateButton) {
                            deleteDateButton.addEventListener(
                                "click",
                                handleDeleteDate
                            );
                        }
                    }
                }
            }
        });

        const element = document.querySelector("div.date-container>div");

        if (element) {
            observer.observe(element, { attributes: true });
        }

        return () => {
            observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateAfterDelete]);

    const handleDeleteDate = () => {
        handleCancelButtonClick();
        handleValueChange("");
        dispatch({ type: "DELETE_FILTERS", payload: "date" });
        setUpdateAfterDelete((prev) => prev + 1);
    };
    const handleCancelButtonClick = () => {
        const dateContainer = document.querySelector(
            "div.date-container > div.transition-all"
        );

        if (dateContainer) {
            dateContainer.classList.remove("opacity-1");
            dateContainer.classList.add("opacity-0");
            dateContainer.addEventListener(
                "transitionend",
                () => {
                    dateContainer.classList.add("hidden");
                    dateContainer.classList.remove("block");
                },
                { once: true }
            );
        }
        dispatch({ type: POPOVER_OPEN, payload: false });
        if (showOptions) dispatch({ type: "SHOW_OPTIONS" });
    };

    const handleValueChange = (newValue: any) => {
        if (!!onChange) {
            onChange({ id, value: newValue ? newValue.startDate : "" });
        } else {
            context?.onChange({ id, value: newValue.startDate });
        }
        onClose?.();
        dispatch({ type: POPOVER_OPEN, payload: false });
        document.body.style.overflow = "";
        if ((!pax || !time) && !showOptions && newValue) {
            dispatch({ type: "SHOW_OPTIONS", payload: true });
        }
    };

    return (
        <div {...(className ? { className } : {})}>
            <div
                className={`relative shadow-sm inline-flex items-center h-12 mt-0 ${
                    fullWidth ? "w-full" : "w-auto"
                }  ${
                    button_border_radius === "round"
                        ? "rounded-5xl"
                        : "rounded-none"
                }`}
            >
                {startAdornment && (
                    <div className="absolute z-10 ml-4 top-1/4">
                        {startAdornment}
                    </div>
                )}
                <Datepicker
                    key={updateAfterDelete}
                    readOnly={true}
                    i18n={document.documentElement.lang}
                    asSingle={true}
                    value={{
                        startDate: getValue(id, context, value),
                        endDate: getValue(id, context, value),
                    }}
                    startWeekOn="mon"
                    useRange={false}
                    onChange={handleValueChange}
                    displayFormat={"MMM.D, YYYY"}
                    containerClassName={`date-container w-full relative ${
                        getValue(id, context, value)
                            ? "delete-show"
                            : "delete-hidden"
                    }`}
                    popoverDirection="down"
                    placeholder={placeholder ? placeholder : " "}
                    {...restProps}
                />
            </div>
        </div>
    );
};

export default DatePickerInput;
