import { FC } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalContent, ModalTitle } from "ui";
import { IRestaurant } from "../interfaces";
import RestaurantListCard from "../components/RestaurantListCard";
import { getColorByBgColor } from "helpers/colorShades";
interface ICardModal {
    item: IRestaurant;
    idx: number;
    handleCloseModal: Function;
}
const CardModal: FC<ICardModal> = ({ handleCloseModal, item }) => {
    const { background } = useSelector((state: any) => state.app.settings.data);

    return (
        <>
            <Modal
                key={`modal-card-${item.id}`}
                open
                size={"md"}
                handler={() => {}}
                style={{ background }}
                className="relative flex flex-col h-auto overflow-hidden text-Gray-80 rounded-2xl sm:!max-w-[90%] md:!max-w-[650px] !min-w-[250px] !w-full !max-w-full"
            >
                <ModalTitle
                    onClose={() => handleCloseModal(item)}
                    iconProps={{
                        color: getColorByBgColor(background),
                    }}
                />
                <ModalContent className="relative flex flex-col flex-auto px-0 py-0 overflow-y-auto !rounded-t-lg p-8 pt-0 ">
                    <RestaurantListCard
                        item={item}
                        key={`restaurant-card-${item.id}`}
                        handleCloseOnMap={() => handleCloseModal(item)}
                        isOnMap={true}
                    />
                </ModalContent>
            </Modal>
        </>
    );
};

export default CardModal;
