import React from "react";

export interface IFormContext {
    appendData: Function;
    changed: boolean;
    data: { [key: string]: any };
    dataAll: { [key: string]: any };
    getFieldError: (field: string) => any;
    getFieldSettings: (field: string) => any;
    getParam: (param: string) => any;
    onChange: Function;
    onSubmit: (
        event: React.FormEvent<HTMLFormElement | HTMLButtonElement>
    ) => void;
}

const FormContext = React.createContext<IFormContext | null>(null);

export default FormContext;
