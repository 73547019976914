import React, { FC, MouseEventHandler, ReactNode, useContext } from "react";
import { getHelperText, getValue, regexValue } from "./helpers";
import FormContext from "./FormContext";

export interface IGPTextField {
    errorText?: string;
    id: string;
    onChange?: Function;
    regex?: string;
    helperText?: string;
    value?: string;
    label?: string;
    type?: string;
    startAdornment?: ReactNode;
    placeholder?: string;
    fullWidth?: true;
    className?: string;
    onClick?: MouseEventHandler<HTMLInputElement>;
    ref?: any;
}

const GPTextField: FC<IGPTextField> = (props) => {
    const {
        errorText,
        fullWidth,
        helperText,
        id,
        label,
        onChange,
        regex,
        startAdornment,
        className,
        type,
        value,
        onClick,
        ...restProps
    } = props;

    const context = useContext(FormContext);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = regexValue(id, context, regex, event.target.value);

        if (!!onChange) {
            onChange({ id, value: val }, context);
        } else {
            context?.onChange({ id, value: val });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );
    return (
        <div {...(className ? { className } : {})}>
            <label className="block text-lg font-medium leading-6 tracking-tighter">
                {label}
            </label>
            <div
                className={`relative rounded shadow-sm inline-flex items-center mt-0 ${
                    fullWidth ? "w-full" : "w-52"
                }`}
            >
                <span className="absolute left-0 h-6 ml-2 top-1/4">
                    {startAdornment && startAdornment}
                </span>
                <input
                    {...restProps}
                    type={type}
                    name={id}
                    id={id}
                    className={`${
                        startAdornment ? "pl-10 " : "pl-2"
                    } text-left block w-full h-12 rounded border-0 py-1.5 pr-4  text-Gray-70 placeholder:text-Gray-40 text-base leading-6 focus:outline-none ${
                        isError && `border-[1px] border-red-700 focus:border-2`
                    } ${type === "button" ? "cursor-pointer" : ""}`}
                    onChange={onChangeValue}
                    value={getValue(id, context, value)}
                    {...(onClick && {onClick})}
                />
            </div>
            {inputHelperText && (
                <span
                    className={`text-white h-5 w-full block text-sm ${
                        isError ? "text-red-700" : ""
                    }`}
                >
                    {inputHelperText}
                </span>
            )}
            <span className="block w-full h-5 text-sm text-red-700">
                {isError && errorText}
            </span>
        </div>
    );
};

GPTextField.defaultProps = {
    errorText: "",
    helperText: "",
};

export default GPTextField;
