import CardModal from "./modals/CardModal";
import FilterModal from "./modals/FilterModal";
import GeneralFiltersModal from "./modals/GeneralFiltersModal"
import ImageModal from "./modals/ImageModal";
import RestauarantsSaga from "./saga";

import { registerModals } from "modals";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { configType, permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";
import RestaurantsReducer from "./reducers/RestaurantsReducer";

export const url = "/p";

const getConfig = (permissions: permissionsType) => {
    const config: configType = {
        permissions: {
            read: true,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    routing.push({
        path: `${url}/:groupId`,
        element: (
            <AsyncComponent
                path="Restaurants/pages/RestaurantsIndexPage"
                isMap={false}
            />
        ),
    });
    routing.push({
        path: `${url}/:groupId/map`,
        element: (
            <AsyncComponent
                path="Restaurants/pages/RestaurantsIndexPage"
                isMap={true}
            />
        ),
    });
    routing.push({
        path: `${url}/:groupId/restaurant/:id`,
        element: <AsyncComponent path="Restaurants/pages/RestaurantPage" />,
    });
    store.reducerManager?.add("Restaurants", RestaurantsReducer);
    store.injectSaga?.("RestauarantsSaga", RestauarantsSaga);

    registerModals({
        FilterModal,
        GeneralFiltersModal,
        ImageModal,
        CardModal,
    });
    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
