import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    fetch: (config: AxiosRequestConfig) => api.get(`/restaurants`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/restaurants/${url.id}`, config),
};

export default requests;
