import { FC } from "react";
import { IIcon } from "ui/types";

const WebIcon: FC<IIcon> = ({color}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                d="M21.232 18.4C21.328 17.608 21.4 16.816 21.4 16C21.4 15.184 21.328 14.392 21.232 13.6H25.288C25.48 14.368 25.6 15.172 25.6 16C25.6 16.828 25.48 17.632 25.288 18.4M19.108 25.072C19.828 23.74 20.38 22.3 20.764 20.8H24.304C23.1414 22.8019 21.2969 24.3184 19.108 25.072ZM18.808 18.4H13.192C13.072 17.608 13 16.816 13 16C13 15.184 13.072 14.38 13.192 13.6H18.808C18.916 14.38 19 15.184 19 16C19 16.816 18.916 17.608 18.808 18.4ZM16 25.552C15.004 24.112 14.2 22.516 13.708 20.8H18.292C17.8 22.516 16.996 24.112 16 25.552ZM11.2 11.2H7.696C8.84663 9.19266 10.6898 7.67378 12.88 6.928C12.16 8.26 11.62 9.7 11.2 11.2ZM7.696 20.8H11.2C11.62 22.3 12.16 23.74 12.88 25.072C10.6943 24.318 8.85382 22.8013 7.696 20.8ZM6.712 18.4C6.52 17.632 6.4 16.828 6.4 16C6.4 15.172 6.52 14.368 6.712 13.6H10.768C10.672 14.392 10.6 15.184 10.6 16C10.6 16.816 10.672 17.608 10.768 18.4M16 6.436C16.996 7.876 17.8 9.484 18.292 11.2H13.708C14.2 9.484 15.004 7.876 16 6.436ZM24.304 11.2H20.764C20.3884 9.71375 19.8323 8.27908 19.108 6.928C21.316 7.684 23.152 9.208 24.304 11.2ZM16 4C9.364 4 4 9.4 4 16C4 19.1826 5.26428 22.2348 7.51472 24.4853C8.62902 25.5996 9.95189 26.4835 11.4078 27.0866C12.8637 27.6896 14.4241 28 16 28C19.1826 28 22.2348 26.7357 24.4853 24.4853C26.7357 22.2348 28 19.1826 28 16C28 14.4241 27.6896 12.8637 27.0866 11.4078C26.4835 9.95189 25.5996 8.62902 24.4853 7.51472C23.371 6.40042 22.0481 5.5165 20.5922 4.91345C19.1363 4.31039 17.5759 4 16 4Z"
                fill={color ? color : "#323947"}
            />
        </svg>
    );
};

export default WebIcon;
