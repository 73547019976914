import { Button, ButtonProps } from "@material-tailwind/react";
import useFontForGroup from "hooks/useFontForGroup";
import { forwardRef } from "react";
 
interface IGPUIButton extends Omit<ButtonProps, "component"> {
    component?: any;
    to?: string;
}

const GPUIButton = forwardRef<HTMLButtonElement, IGPUIButton>((props, ref) => {
    const {children, className, ...restProps} = props
    const raleway = useFontForGroup()
    
    return <Button {...restProps} ref={ref} className={`${raleway ? "font-raleway" : "font-inter"} ${className} outline-0`}>{children}</Button>;
});

GPUIButton.defaultProps = {
    variant: "filled",
};

export default GPUIButton;
