import { adjustContrast, getColorByBgColor } from "helpers/colorShades";
import { url } from "../config";
import { IRestaurantCard } from "../interfaces";

import trans from "helpers/trans";
import { sortBy } from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ArrowLeftSolidIcon, ArrowRightSolidIcon, CustomColorButton } from "ui";
import useFontForGroup from "hooks/useFontForGroup";

const RestaurantListCard: FC<IRestaurantCard> = ({
    item,
    isOnMap,
    handleCloseOnMap,
}) => {
    const containerRef = useRef<any>([]);

    const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
    const [scrollPositions, setScrollPositions] = useState<number>(0);
    const moniters = useFontForGroup();
    const lang = document.documentElement.lang;

    const { filters, urlParams } = useSelector((state: any) => state.app);
    const {
        background,
        button_background,
        button_border_color,
        button_hover_color,
        button_border_radius,
    } = useSelector((state: any) => state.app.settings.data);

    const closestTime = useMemo(() => {
        const time = moment(filters.time, "HH:mm");
        if (item.availability.length === 0) return null;
        const hours = item.availability.map((hour: string) =>
            moment(hour, "HH:mm")
        );
        let closestTime = hours[0];
        let minDiff = Math.abs(time.diff(hours[0], "minutes"));

        for (let i = 1; i < hours.length; i++) {
            const diff = Math.abs(time.diff(hours[i], "minutes"));
            if (diff < minDiff) {
                minDiff = diff;
                closestTime = hours[i];
            }
        }

        return closestTime.format("HH:mm");
    }, [filters, item]);

    useEffect(() => {
        const container = containerRef.current;
        if (container && closestTime) {
            const closestItemIndex = item.availability.indexOf(closestTime);
            const containerWidth = container.clientWidth;
            const childWidth = container.children[closestItemIndex].offsetWidth;
            const childOffsetLeft =
                container.children[closestItemIndex].offsetLeft;

            const newPosition =
                childOffsetLeft + childWidth / 2 - containerWidth / 2;
            container.scrollTo({
                left: newPosition,
                behavior: "smooth",
            });
            setScrollPositions(newPosition);
        }
    }, [closestTime, item.availability]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const updateOverflow = () => {
                setIsOverflowing(container.scrollWidth > container.clientWidth);
                setScrollPositions(container.scrollLeft);
            };

            updateOverflow();
            window.addEventListener("resize", updateOverflow);
            return () => {
                window.removeEventListener("resize", updateOverflow);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScroll = (direction: "left" | "right") => {
        const container = containerRef.current;
        if (container) {
            const maxScroll =
                container.scrollWidth - container.clientWidth + 150;
            let newPosition = scrollPositions;

            if (direction === "left") {
                newPosition = newPosition - 150;
            } else {
                newPosition = newPosition + 150;
            }
            const diff = maxScroll - newPosition;
            if (Math.floor(diff) > 0 && diff < 150) {
                container.scrollTo({ left: maxScroll, behavior: "smooth" });
                setScrollPositions(maxScroll);
                return;
            }
            if (newPosition <= 0 || Math.ceil(newPosition) >= maxScroll) {
                container.scrollTo({ left: 0, behavior: "smooth" });
                setTimeout(() => {
                    setScrollPositions(0);
                }, 900);
            } else {
                container.scrollTo({ left: newPosition, behavior: "smooth" });
                setScrollPositions(newPosition);
            }
        }
    };

    return (
        <div
            {...(!isOnMap && { id: `item-${item.id}` })}
            key={`item-${item.id}`}
            className={`relative flex flex-col w-full gap-6  bg-transparent border-gray-200 ${
                isOnMap ? "" : "p-8 pl-0 border-b-2"
            }`}
        >
            <NavLink
                to={`${url}/${urlParams.group_id}/restaurant/${item.id}`}
                onClick={() => handleCloseOnMap?.()}
            >
                <div
                    className={`relative flex flex-row flex-wrap justify-between gap-6 overflow-hidden bg-no-repeat bg-cover  group ${
                        isOnMap ? "sm:flex-nowrap" : "lg:flex-nowrap"
                    }`}
                    style={{ color: getColorByBgColor(background) }}
                >
                    <div
                        className={`gap-4 justify-start w-full break-words ${
                            isOnMap ? "sm:w-[48%]" : "lg:w-[48%]"
                        }`}
                    >
                        <h5
                            className={`ml-[1px] mb-2 text-2xl font-bold ${
                                moniters ? "font-moniters" : "font-inter"
                            }`}
                        >
                            {item.name}
                        </h5>
                        <p className="items-stretch text-base">
                            {item.address_1}
                            {item.city ? `, ${item.city}` : ""}
                        </p>
                    </div>
                    {item.image_listing ? (
                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                        <img
                            src={item.image_listing}
                            alt={`card-image-${item.id}`}
                            className={`h-auto w-full ${
                                isOnMap ? "sm:w-[48%]" : "lg:w-[48%]"
                            }`}
                        />
                    ) : (
                        <div
                            className={`h-auto w-full ${
                                isOnMap ? "sm:w-[48%]" : "lg:w-[48%]"
                            }`}
                        ></div>
                    )}
                </div>
            </NavLink>
            {(item.availability.length > 0 ||
                item.cuisine.length > 0 ||
                item.short_description[lang] || item.short_description.nb) && (
                <div className="relative flex flex-col justify-between h-full gap-4 p-4 pb-8 bg-white rounded-lg">
                    <div className="flex flex-col gap-2">
                        <div className="text-sm text-Gray-60">
                            {item.cuisine
                                .map((cuisine: number) =>
                                    trans(`booking.predefinedList.${cuisine}`)
                                )
                                .join(", ")}
                        </div>
                        <div className="text-sm sm:text-base leading-[140%] h-auto text-Gray-60 font-normal">
                            {item.short_description[lang] || item.short_description.nb}
                        </div>
                    </div>
                    <div
                        className={`${
                            isOverflowing && scrollPositions > 0
                                ? "gradient-after gradient-before"
                                : isOverflowing && scrollPositions === 0
                                ? "gradient-before"
                                : ""
                        } flex flex-row items-center relative`}
                    >
                        <div
                            className={`z-10 flex justify-start w-auto h-full ${
                                scrollPositions <= 0
                                    ? "opacity-0"
                                    : "opacity-60"
                            } transition-opacity duration-300 ease-in-out`}
                        >
                            {isOverflowing && scrollPositions > 0 && (
                                <button onClick={() => handleScroll("left")}>
                                    <ArrowLeftSolidIcon
                                        color={adjustContrast(
                                            "#FFFFFF",
                                            button_background
                                                ? button_background
                                                : button_border_color
                                                ? button_border_color
                                                : button_hover_color
                                        )}
                                    />
                                </button>
                            )}
                        </div>
                        <ul
                            className="inline-flex w-full h-full gap-2 overflow-auto sm:overflow-hidden"
                            ref={containerRef}
                            onScroll={() => {
                                const container = containerRef.current;
                                if (container) {
                                    const newPosition = container.scrollLeft;
                                    setTimeout(() => {
                                        setScrollPositions(newPosition);
                                    }, 300);
                                }
                            }}
                        >
                            {sortBy(item.availability).map(
                                (hour: string, idx: number) => {
                                    const params = `?date=${filters.date}&time=${hour}&guests=${filters.pax}&locale=${lang}`;
                                    return (
                                        <li key={`hour-item-${idx}`}>
                                            <NavLink
                                                to={`${item.links.online_booking}${params}`}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                <CustomColorButton
                                                    customcolor={
                                                        button_background
                                                    }
                                                    border={button_border_color}
                                                    hovercolor={
                                                        button_hover_color
                                                    }
                                                    fontcolor={
                                                        button_background
                                                            ? getColorByBgColor(
                                                                  button_background
                                                              )
                                                            : "black"
                                                    }
                                                    className={`${
                                                        button_border_color
                                                            ? "py-1.5 px-3.5"
                                                            : "px-4 py-2"
                                                    } text-base font-normal duration-300 ease-in-out ${
                                                        button_border_radius ===
                                                        "round"
                                                            ? "rounded"
                                                            : "rounded-none"
                                                    }`}
                                                >
                                                    {hour}
                                                </CustomColorButton>
                                            </NavLink>
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                        <div className="z-10 flex justify-end w-auto h-full opacity-60">
                            {isOverflowing && (
                                <button onClick={() => handleScroll("right")}>
                                    <ArrowRightSolidIcon
                                        color={adjustContrast(
                                            "#FFFFFF",
                                            button_background
                                                ? button_background
                                                : button_border_color
                                                ? button_border_color
                                                : button_hover_color
                                        )}
                                    />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RestaurantListCard;
