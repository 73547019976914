const queryMiddleware = (store) => (next) => (action) => {
    if (action.type.indexOf("_REQUEST") !== -1) {
        if (!action.query || !action.query.cacheKey) {
            return next(action);
        }

        const query = store.getState().api.queries[action.query.cacheKey];

        if (!query) {
            return next({
                ...action,
                query: {
                    ...action.query,
                    meta: {
                        currentData: undefined,
                        data: undefined,
                        errors: undefined,
                        isError: false,
                        isLoading: true,
                        isPending: true,
                        isSuccess: false,
                        message: "",
                        status: 0,
                    },
                },
            });
        } else {
            if (query.isLoading) {
                return;
            }

            return next({
                ...action,
                query: {
                    ...action.query,
                    meta: {
                        ...store.getState().api.queries[action.query.cacheKey],
                        data: undefined,
                        errors: undefined,
                        isError: false,
                        isLoading: true,
                        isSuccess: false,
                        message: "",
                        status: 0,
                    },
                },
            });
        }
    } else if (action.type.indexOf("_SUCCESS") !== -1) {
        if (!action.query || !action.query.cacheKey) {
            return next(action);
        }

        return next({
            ...action,
            query: {
                ...action.query,
                meta: {
                    ...store.getState().api.queries[action.query.cacheKey],
                    ...action.response,
                    data: action.response?.data,
                    currentData: action.response?.data,
                    errors: undefined,
                    isLoading: false,
                    isPending: false,
                    isSuccess: true,
                    message: action.response?.data.message,
                },
            },
        });
    } else if (action.type.indexOf("_FAILED") !== -1) {
        if (!action.query || !action.query.cacheKey) {
            return next(action);
        }

        const sub = store
            .getState()
            .api.subscribtions[action.query.cacheKey]?.indexOf(
                action.query.subscribeUid
            );

        if (sub === undefined || sub === -1) {
            return;
        }

        return next({
            ...action,
            query: {
                ...action.query,
                meta: {
                    ...store.getState().api.queries[action.query.cacheKey],
                    ...action.response,
                    data: action.response?.data,
                    currentData: action.response?.data,
                    errors: action.response?.data?.errors,
                    isError: true,
                    isLoading: false,
                    isPending: false,
                    message: action.response?.data?.message,
                },
            },
        });
    } else if (action.type === "API_UNSUBSCRIBE") {
        const api = store.getState().api;
        let queriesToRemove = [];
        let subscribtions = { ...api.subscribtions };

        for (let cacheKey of Object.keys(subscribtions)) {
            const sub = subscribtions[cacheKey].filter(
                (item) => item !== action.uid
            );

            if (sub.length === 0) {
                queriesToRemove.push(cacheKey);
                delete subscribtions[cacheKey];
            } else {
                subscribtions[cacheKey] = sub;
            }
        }

        return next({
            ...action,
            queriesToRemove,
            subscribtions,
        });
    }

    return next(action);
};

export default queryMiddleware;
