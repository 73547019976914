import {
    APPEND_PARAMS,
    APP_INIT,
    APP_LOADED,
    APP_SETTINGS_SUCCESS,
    DELETE_FILTERS,
    LOCALE_REQUEST,
    LOCALE_SUCCESS,
    MODAL_HIDE,
    MODAL_SHOW,
    POPOVER_OPEN,
    SHOW_OPTIONS,
    SNACKBAR_HIDE,
    SNACKBAR_SHOW,
    UPDATE_PARAMS,
} from "types";

import { AnyAction } from "redux";

import { rs } from "helpers/reducers";
import { isEmpty } from "lodash";

interface IState {
    loaded: boolean;
    deleteFilters: string,
    showOptions: boolean;
    popoverOpen: boolean;
    filters: any;
    modals: Array<{
        id: string;
    }>;
    urlParams: any;
    request: any;
    settings: any;
    snackbar: any;
    sideNavType: string;
}
function general(
    state: IState = {
        loaded: false,
        showOptions: false,
        popoverOpen: false,
        deleteFilters: "",
        filters: {},
        modals: [],
        urlParams: {},
        request: {},
        settings: {},
        snackbar: null,
        sideNavType: "main",
    },
    action = {} as AnyAction
) {
    switch (action.type) {
        case APP_INIT:
            return rs.init(state);

        case APP_LOADED:
            return {
                ...state,
                loaded: isEmpty(state.settings) ? false : true,
            };
        case APPEND_PARAMS:
            const filters = action.filters
            return {
                ...state,
                urlParams: action.urlParams,
                filters,
            };
        case UPDATE_PARAMS:
            const page = action.data.page ? action.data.page : 1;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.data.filters,
                },
                urlParams: {
                    ...state.urlParams,
                    page: page,
                    // _search: action.data.search,
                },
            };
        case APP_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.response.data,
            };
        case MODAL_HIDE:
            return {
                ...state,
                modals: state.modals.filter((modal) => modal.id !== action.id),
            };
        case LOCALE_REQUEST:
            return {
                ...state,
                loaded: false,
            };
        case LOCALE_SUCCESS:
            return {
                ...state,
                loaded: true,
            };
        case MODAL_SHOW:
            return {
                ...state,
                modals: [
                    ...state.modals,
                    {
                        id: action.id,
                        props: action.props,
                    },
                ],
            };
        case DELETE_FILTERS:
            return {
                ...state,
                deleteFilters: action.payload
            }
        case SHOW_OPTIONS:
            return {
                ...state,
                showOptions: action.payload ? action.payload : false,
            };
        case POPOVER_OPEN:
            return {
                ...state,
                deleteFilters: "",
                popoverOpen: action.payload,
            };
        case SNACKBAR_HIDE:
            return {
                ...state,
                snackbar: null,
            };
        case SNACKBAR_SHOW:
            return {
                ...state,
                snackbar: action.data,
            };

        default:
            return state;
    }
}

export default general;
