import { DialogFooter, DialogFooterProps } from "@material-tailwind/react";
import useFontForGroup from "hooks/useFontForGroup";
import { forwardRef } from "react";

interface IGPModalActions extends DialogFooterProps {}

const GPModalActions = forwardRef<HTMLDivElement, IGPModalActions>(
    (props, ref) => {
        const { children, className, ...restProps } = props;
        const raleway = useFontForGroup()

        return (
            <DialogFooter {...restProps} ref={ref} className={`${raleway ? "font-raleway" : "font-inter"} ${className}`}>
                {children}
            </DialogFooter>
        );
    }
);

export default GPModalActions;
