import { useRoutes } from "react-router-dom";
import NotFound from "app/App/pages/NotFound";

let routings = [];

export function registerRoutings(data) {
    routings.push(...data);
}

const AppRouting = () => {
    return useRoutes([
        ...routings,
        { path: "*", element: <NotFound /> },
    ]);
};

export default AppRouting;
