import { XMarkIcon } from "@heroicons/react/24/outline";
import { DialogHeader, DialogHeaderProps } from "@material-tailwind/react";
import useFontForGroup from "hooks/useFontForGroup";

import React, { forwardRef } from "react";

interface IGPModalTitle extends Omit<DialogHeaderProps, "children"> {
    onClose: (event: React.MouseEvent) => void;
    actions?: React.ReactNode;
    children?: React.ReactNode;
    iconProps?: {
        color?: string;
        height?: number;
        width?: number;
    };
}

const GPModalTitle = forwardRef<HTMLDivElement, IGPModalTitle>((props, ref) => {
    const { actions, children, className, onClose, iconProps, ...restProps } = props;
    const raleway = useFontForGroup()

    return (
        <DialogHeader {...restProps} ref={ref} className={`${raleway ? "font-raleway" : "font-inter"} ${className}`}>
            <div className="flex flex-row items-center justify-between w-full">
                <div>{children}</div>
                <div className="flex flex-row justify-center gap-1">
                    {!!actions && actions}
                    {!!onClose && (
                        <button onClick={onClose} className="outline-0">
                            <XMarkIcon
                                {...iconProps}
                                width={iconProps?.width ? iconProps.width : 32}
                                height={iconProps?.height ? iconProps.height : 32}
                                color={
                                    iconProps?.color ? iconProps.color : "black"
                                }
                            />
                        </button>
                    )}
                </div>
            </div>
        </DialogHeader>
    );
});

export default GPModalTitle;
