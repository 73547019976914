import { API_UNSUBSCRIBE } from "./types";

function api(state = { queries: {}, subscribtions: {} }, action = {}) {
    if (!!action.query?.cacheKey) {
        let subs = {
            ...state.subscribtions,
        };

        if (!state.subscribtions[action.query.cacheKey]) {
            subs[action.query.cacheKey] = [action.query.subscribeUid];
        } else {
            if (
                subs[action.query.cacheKey].indexOf(
                    action.query.subscribeUid
                ) === -1
            ) {
                subs[action.query.cacheKey] = [
                    ...subs[action.query.cacheKey],
                    action.query.subscribeUid,
                ];
            }
        }

        return {
            ...state,
            queries: {
                ...state.queries,
                [action.query.cacheKey]: action.query.meta,
            },
            subscribtions: subs,
        };
    } else if (action.type === API_UNSUBSCRIBE) {
        return {
            ...state,
            queries: Object.keys(state.queries).reduce((result, key) => {
                if (action.queriesToRemove.indexOf(key) === -1) {
                    result[key] = state.queries[key];
                }

                return result;
            }, {}),
            subscribtions: action.subscribtions,
        };
    }

    return state;
}

export default api;
