import { FC } from "react";
import { LogoIcon } from "ui";

const AppLoader: FC = () => {
    return (
        <div className="fixed top-0 left-0 flex flex-col items-center justify-center w-full h-screen logo-gradient">
            <div className="max-w-[250px] rounded shadow overflow-hidden pb-4 text-center w-full bg-white text-black ">
                <LogoIcon className="logo-beat text-[80px] m-4 relative animate-beat transform-origin-center text-[#36845B] inline-block" />
                <div className="text-2xl">Portal</div>
            </div>
        </div>
    );
};

export default AppLoader;
