import theme from "@material-tailwind/react/theme";
import { merge } from "lodash";

const customTheme = {
    button: {
        styles: {
            base: {
                initial: {
                    textTransform: "none"
                }
            }
        }
    },
    select: {
        styles: {
            base: {
                container: {
                    minWidth: "min-w-0",
                    height: "h-12"
                },
                arrow: {
                    initial: {
                        width: "w-6",
                        height: "h-6",
                    },
                },
            },
        },
    },
    checkbox: {
        styles: {
            base: {
                input: {
                    before: {
                        width: "before:w-11",
                        height: "before:h-11",
                    }
                }
            }
        }
    }
};

const mergedTheme = merge(theme, customTheme);

export default mergedTheme;
