import types from "./types";

import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const restaurantsFetch = (
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "restaurantsFetch",
        {
            config,
        },
        onResponse
    );

export const restaurantsGet = (id: number) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "restaurantsGet",
        {
            url: {
                id,
            },
        }
    );

export const shouldScroll = (scroll: boolean) => ({
    type: types.SHOULD_SCROLL,
    scroll,
});
