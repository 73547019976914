import { FC } from "react";

const LocationIcon: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M12 3C10.1435 3 8.36301 3.7375 7.05025 5.05025C5.7375 6.36301 5 8.14348 5 10C5 12.862 6.782 15.623 8.738 17.762C9.73868 18.8511 10.8299 19.8533 12 20.758C13.1701 19.854 14.2613 18.8525 15.262 17.764C17.218 15.623 19 12.863 19 10C19 8.14348 18.2625 6.36301 16.9497 5.05025C15.637 3.7375 13.8565 3 12 3ZM12 23.214L11.433 22.824L11.43 22.822L11.424 22.817L11.404 22.803L11.329 22.75C10.9149 22.449 10.5101 22.1355 10.115 21.81C9.10237 20.9782 8.14906 20.0766 7.262 19.112C5.218 16.876 3 13.637 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10C21 13.637 18.782 16.877 16.738 19.112C15.5799 20.3717 14.3093 21.5231 12.942 22.552C12.8281 22.6371 12.7131 22.7208 12.597 22.803L12.576 22.817L12.57 22.822L12.568 22.823L12 23.213V23.214ZM12 8C11.4696 8 10.9609 8.21071 10.5858 8.58579C10.2107 8.96086 10 9.46957 10 10C10 10.5304 10.2107 11.0391 10.5858 11.4142C10.9609 11.7893 11.4696 12 12 12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 10C14 9.46957 13.7893 8.96086 13.4142 8.58579C13.0391 8.21071 12.5304 8 12 8ZM8 10C8 8.93913 8.42143 7.92172 9.17157 7.17157C9.92172 6.42143 10.9391 6 12 6C13.0609 6 14.0783 6.42143 14.8284 7.17157C15.5786 7.92172 16 8.93913 16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10Z"
                fill="black"
            />
        </svg>
    );
};

export default LocationIcon;
