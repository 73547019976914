import { modalHide, updateParams } from "app/App/actions";
import { FC, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    CustomColorButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RefreshIcon,
    RequestMessage,
} from "ui";
import FilterForm from "../forms/FilterForm";
import AppContext, { IAppContext } from "app/App/context";
import trans from "helpers/trans";
import useRequest from "api/useRequest";
import { restaurantsFetch } from "../actions";
import { AxiosResponse } from "axios";
import { Spinner } from "@material-tailwind/react";
import { mapKeys, omitBy } from "lodash";
import { IFilters, IPredefinedListFilters } from "../interfaces";
import { getColorByBgColor } from "helpers/colorShades";

const FilterModal: FC = () => {
    const context = useContext(AppContext) as IAppContext;
    const dispatch = useDispatch();

    const {
        button_background,
        button_border_color,
        background,
        button_hover_color,
        button_border_radius,
    } = useSelector((state: any) => state.app.settings.data);

    const { filters, urlParams } = useSelector((state: any) => state.app);

    const [refresh, setRefresh] = useState<number>(0);
    const [submitForm, setSubmitForm] = useState<boolean>(false);

    const { errors, isLoading, message, request, status } = useRequest();

    const filtersToSend = useMemo(() => {
        return mapKeys(
            omitBy(filters, (value) => value === ""),
            (value, key) => `${key}_filter`
        );
    }, [filters]);

    const handleCloseModal = () => {
        dispatch(modalHide("FilterModal"));
    };

    const handleRefresh = () => {
        setRefresh((prev) => prev + 1);
    };

    const handleSubmit = (formData: IPredefinedListFilters) => {
        const { time_filter, ...finalFilters } = filtersToSend;
        request(
            restaurantsFetch(
                {
                    params: {
                        ...urlParams,
                        _filters: { ...finalFilters, ...formData._filters },
                        _sort: { position: "asc" },
                    },
                },
                (response: AxiosResponse) => {
                    if (response.status === 200) {
                        handleCloseModal();
                        dispatch(
                            updateParams({
                                filters: mapKeys(
                                    formData._filters,
                                    (value, key) => key.replace("_filter", "")
                                ),
                            })
                        );
                    } else setSubmitForm(false);
                }
            )
        );
    };

    return (
        <>
            <Modal
                open
                size={context.isMobile ? "xxl" : "sm"}
                handler={() => {}}
                className="flex flex-col relative bg-white text-Gray-80 sm:rounded-md h-full sm:h-[calc(60vh-1rem)] w-auto overflow-hidden"
            >
                <ModalTitle
                    iconProps={{
                        color: "black",
                    }}
                    onClose={handleCloseModal}
                    className="px-5 py-2 pb-1 text-lg font-medium leading-6 text-black md:text"
                >
                    <div className="inline-flex items-center gap-4 text-2xl font-bold text-Gray-80">
                        {trans("restaurant.modal.filterModal.title")}{" "}
                        <div
                            onClick={handleRefresh}
                            className="flex flex-row items-center justify-center gap-2 text-lg font-medium cursor-pointer sm:hidden text-Gray-80"
                        >
                            <RefreshIcon />{" "}
                            {trans("restaurant.modal.filterModal.reset")}
                        </div>
                    </div>
                </ModalTitle>

                <ModalContent className="relative flex flex-col flex-1 px-5 py-2 pt-0 overflow-y-auto ">
                    <RequestMessage
                        message={message}
                        status={status}
                        className="mb-2"
                    />
                    <FilterForm
                        errors={errors}
                        refresh={refresh}
                        data={
                            {
                                _filters: filtersToSend,
                            } as IFilters
                        }
                        loading={false}
                        onSubmit={handleSubmit}
                        submitForm={submitForm}
                    />
                </ModalContent>
                <ModalActions
                    style={{
                        background:
                            context.winSize.width >= 481 ? background : "white",
                        color:
                            context.winSize.width >= 481
                                ? getColorByBgColor(background)
                                : "black",
                    }}
                    className="relative bottom-auto flex flex-col items-center w-full px-5 py-4 shrink-0 sm:rounded-b-md sm:justify-between sm:flex-nowrap sm:flex-row sm:py-6"
                >
                    <div
                        onClick={handleRefresh}
                        className="flex-row items-center justify-center hidden gap-2 font-medium cursor-pointer sm:flex "
                    >
                        <RefreshIcon
                            color={
                                context.winSize.width >= 481
                                    ? getColorByBgColor(background)
                                    : "black"
                            }
                        />{" "}
                        {trans("restaurant.modal.filterModal.reset")}
                    </div>
                    <div className="flex flex-col items-center justify-center w-full gap-2 sm:flex-row-reverse sm:justify-start">
                        <CustomColorButton
                            customcolor={button_background}
                            border={button_border_color}
                            hovercolor={button_hover_color}
                            fontcolor={
                                !button_background &&
                                context.winSize.width >= 481
                                    ? getColorByBgColor(background)
                                    : !button_background
                                    ? "black"
                                    : getColorByBgColor(button_background)
                            }
                            className={`${
                                button_border_color
                                    ? "px-[54px] py-1.5"
                                    : "px-14 py-2"
                            } flex items-center justify-center w-full  text-lg font-bold duration-300 ease-in-out md:w-fit sm:w-36 ${
                                button_border_radius === "round"
                                    ? "rounded-5xl"
                                    : "rounded-none"
                            }`}
                            onClick={() => setSubmitForm(true)}
                        >
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                trans("restaurant.modal.filterModal.use")
                            )}
                        </CustomColorButton>
                        <Button
                            variant="text"
                            onClick={handleCloseModal}
                            style={{
                                color:
                                    context.winSize.width >= 481
                                        ? getColorByBgColor(background)
                                        : "black",
                            }}
                            className={`flex justify-center w-full py-2 text-lg font-bold duration-300 ease-in-out sm:w-36 px-14 ${
                                button_border_radius === "round"
                                    ? "rounded-5xl"
                                    : "rounded-none"
                            }`}
                        >
                            {trans("restaurant.modal.filterModal.close")}
                        </Button>
                    </div>
                </ModalActions>
            </Modal>
        </>
    );
};

export default FilterModal;
