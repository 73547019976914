import { FC, useContext, useMemo } from "react";
import { Modal, ModalContent, ModalTitle } from "ui";
import { modalHide } from "app/App/actions";
import { useDispatch } from "react-redux";
import AppContext, { IAppContext } from "app/App/context";
import { Carousel } from "@material-tailwind/react";

interface IImageModal {
    gallery: string[];
    idx: number;
}
const ImageModal: FC<IImageModal> = ({ gallery, idx }) => {
    const dispatch = useDispatch();
    const context = useContext(AppContext) as IAppContext;
    const handleCloseModal = () => {
        dispatch(modalHide("ImageModal"));
    };
    const prepareGallery = useMemo(() => {
        if (idx !== 0) {
            const rearrangedGallery = [...gallery];
            rearrangedGallery.push(...rearrangedGallery.splice(0, idx));
            return rearrangedGallery;
        }
        return gallery;
    }, [gallery, idx]);

    return (
        <>
            <Modal
                size={context.isMobile ? "xxl" : "xl"}
                open
                handler={() => {}}
                className="flex flex-col fixed sm:relative bg-black text-Gray-80 sm:rounded-md  sm:h-[calc(80vh-1rem)] w-auto overflow-hidden"
            >
                <ModalTitle
                    iconProps={{
                        color: "white",
                    }}
                    onClose={handleCloseModal}
                    className={`border-b-0 py-2 pl-8 pr-6 overflow-hidden text-lg font-medium leading-6 text-black md:text`}
                />

                <ModalContent
                    className={`relative max-w-[1248px] m-auto w-auto h-full p-0 flex justify-center !border-t-0 overflow-y-hidden overflow-x-hidden `}
                >
                    <Carousel loop={true} className="h-[90%] sm:h-full">
                        {prepareGallery.map((imageSrc, index) => (
                            // eslint-disable-next-line jsx-a11y/img-redundant-alt
                            <img
                                key={index}
                                className="object-contain object-center w-full h-full"
                                src={imageSrc}
                                alt={`image-${index}`}
                            />
                        ))}
                    </Carousel>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ImageModal;
