import { FC } from "react";

const DrinkWineIcon: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                d="M21.2387 13.5999C21.2814 13.394 21.2217 13.1829 21.0724 13.0117C20.9231 12.8405 20.6959 12.7228 20.4395 12.6837C20.1831 12.6445 19.9177 12.6871 19.7001 12.8024C19.4824 12.9176 19.3298 13.0963 19.2747 13.3003C19.1403 13.866 18.7783 14.3811 18.244 14.7672C17.8047 15.0869 17.2666 15.307 16.6853 15.4048C16.5567 15.4262 16.4347 15.4678 16.3261 15.527C16.2176 15.5862 16.1247 15.6619 16.0528 15.7498C15.9809 15.8377 15.9313 15.9361 15.9069 16.0393C15.8825 16.1426 15.8838 16.2486 15.9107 16.3514C15.9375 16.4543 15.9895 16.5519 16.0635 16.6386C16.1376 16.7254 16.2323 16.7997 16.3422 16.8572C16.4522 16.9147 16.5752 16.9544 16.7043 16.9738C16.8334 16.9933 16.9661 16.9923 17.0947 16.9708C18.0262 16.8156 18.8883 16.4629 19.5907 15.9495C20.4422 15.3287 21.0202 14.5046 21.2387 13.5999ZM8 6.13306C8 5.921 8.10536 5.71763 8.29289 5.56768C8.48043 5.41774 8.73478 5.3335 9 5.3335H23C23.2652 5.3335 23.5196 5.41774 23.7071 5.56768C23.8946 5.71763 24 5.921 24 6.13306V13.0626C24.0001 14.6914 23.2541 16.2639 21.9029 17.4829C20.5518 18.7019 18.6891 19.483 16.6667 19.6787V25.0656L20.3307 25.056C20.5959 25.0554 20.8505 25.1391 21.0386 25.2887C21.2266 25.4382 21.3326 25.6414 21.3333 25.8534C21.334 26.0655 21.2294 26.2691 21.0423 26.4194C20.8553 26.5698 20.6012 26.6545 20.336 26.6551L15.6693 26.6668H15.6627L11.6627 26.6551C11.3974 26.6543 11.1435 26.5692 10.9567 26.4187C10.7699 26.2681 10.6656 26.0644 10.6667 25.8523C10.6677 25.6403 10.7741 25.4373 10.9624 25.2879C11.1507 25.1386 11.4055 25.0551 11.6707 25.056L14.6667 25.0645V19.6744C12.8223 19.4802 11.1291 18.7565 9.90263 17.6381C8.67612 16.5198 7.99992 15.083 8 13.5956V6.13306ZM10 6.93262V9.86434H22V6.93262H10ZM10 13.5956C10 14.7973 10.597 15.9497 11.6597 16.7994C12.7224 17.6491 14.1638 18.1265 15.6667 18.1265C17.3464 18.1265 18.9573 17.5929 20.145 16.6433C21.3327 15.6936 22 14.4056 22 13.0626V11.4635H10V13.5956Z"
                fill="#323947"
            />
        </svg>
    );
};

export default DrinkWineIcon;
