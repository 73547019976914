export const APP_INIT = "APP_INIT";

export const APP_HANDLE_ERROR = "APP_HANDLE_ERROR";
export const APP_LOADED = "APP_LOADED";

export const APP_SETTINGS_FAILED = "APP_SETTINGS_FAILED";
export const APP_SETTINGS_REQUEST = "APP_SETTINGS_REQUEST";
export const APP_SETTINGS_SUCCESS = "APP_SETTINGS_SUCCESS";

export const APP_TRANSLATE_FAILED = "APP_TRANSLATE_FAILED";
export const APP_TRANSLATE_REQUEST = "APP_TRANSLATE_REQUEST";
export const APP_TRANSLATE_SUCCESS = "APP_TRANSLATE_SUCCESS";

export const CONFIRMATION_CONFIRMED = "CONFIRMATION_CONFIRMED";
export const CONFIRMATION_UNCONFIRMED = "CONFIRMATION_UNCONFIRMED";

export const MODAL_HIDE = "MODAL_HIDE";
export const MODAL_SHOW = "MODAL_SHOW";

export const SNACKBAR_HIDE = "SNACKBAR_HIDE";
export const SNACKBAR_SHOW = "SNACKBAR_SHOW";

export const SIDE_NAV_TYPE = "SIDE_NAV_TYPE";

export const APP_PARAMS = "APP_PARAMS";

export const APPEND_PARAMS = "APPEND_PARAMS";

export const UPDATE_PARAMS = "UPDATE_PARAMS";

export const LOCALE_REQUEST = "LOCALE_REQUEST";
export const LOCALE_SUCCESS = "LOCALE_SUCCESS";
export const LOCALE_FAILED = "LOCALE_FAILED";

export const SHOW_OPTIONS = "SHOW_OPTIONS";
export const POPOVER_OPEN = "POPOVER_OPEN";
export const DELETE_FILTERS = "DELETE_FILTERS";
