import { FC } from "react";

const ArrowDown: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className=""
        >
            <path
                d="M12 14.9758C11.8667 14.9758 11.7374 14.9508 11.612 14.9008C11.4867 14.8508 11.3827 14.7841 11.3 14.7008L6.70005 10.1008C6.51672 9.91745 6.42505 9.68411 6.42505 9.40078C6.42505 9.11745 6.51672 8.88411 6.70005 8.70078C6.88338 8.51745 7.11672 8.42578 7.40005 8.42578C7.68338 8.42578 7.91672 8.51745 8.10005 8.70078L12 12.6008L15.9 8.70078C16.0834 8.51745 16.3167 8.42578 16.6 8.42578C16.8834 8.42578 17.1167 8.51745 17.3 8.70078C17.4834 8.88411 17.575 9.11745 17.575 9.40078C17.575 9.68411 17.4834 9.91745 17.3 10.1008L12.7 14.7008C12.6 14.8008 12.4917 14.8718 12.375 14.9138C12.2584 14.9558 12.1334 14.9764 12 14.9758Z"
                fill="#323947"
            />
        </svg>
    );
};

export default ArrowDown;
