import { DialogBody, DialogBodyProps } from "@material-tailwind/react";
import useFontForGroup from "hooks/useFontForGroup";
import { forwardRef } from "react";

interface IGPModalContent extends DialogBodyProps {}

const GPModalContent = forwardRef<HTMLDivElement, IGPModalContent>(
    (props, ref) => {
        const { children, className, ...restProps } = props;
        const raleway = useFontForGroup()

        return (
            <DialogBody {...restProps} ref={ref} className={`${raleway ? "font-raleway" : "font-inter"} ${className}`}>
                {children}
            </DialogBody>
        );
    }
);

export default GPModalContent;
