import loadable from "@loadable/component";

interface IAsyncComponent {
    isModule?: boolean;
    path: string;
    [key: string]: any;
}

const AsyncComponent = ({
    path,
    isModule = false,
    ...props
}: IAsyncComponent) => {
    const Component = loadable(() => import(`app/${[path]}`));
    return <Component {...props} />;
};

export default AsyncComponent;
