export function adjust(color, amount) {
    if (!color) return ""
    const adjustColorValue = (colorValue) => {
        return (
            "0" +
            Math.min(255, Math.max(0, parseInt(colorValue, 16) + amount)).toString(16)
        ).substr(-2);
    };
    if (color.length === 4) {
        color = "#" + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
    }
    return (
        "#" +
        color
            .replace(/^#/, "")
            .replace(/../g, (color) => adjustColorValue(color))
    );
}

export function getColorByBgColor(hexColor) {
    if (!hexColor) return "";
    if (hexColor.length === 4) {
        hexColor = "#" + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2] + hexColor[3] + hexColor[3];
    }

    hexColor = hexColor.replace("#", "");
    const bigint = parseInt(hexColor, 16);
    const R = (bigint >> 16) & 255;
    const G = (bigint >> 8) & 255;
    const B = bigint & 255;

    const brightness = R * 0.299 + G * 0.587 + B * 0.114;

    return brightness > 186 ? "#000000" : "#FFFFFF";
}

export function contrast(color1, color2) {
    if (color1?.length === 4) {
        color1 = "#" + color1[1] + color1[1] + color1[2] + color1[2] + color1[3] + color1[3];
    }
    if (color2?.length === 4) {
        color2 = "#" + color2[1] + color2[1] + color2[2] + color2[2] + color2[3] + color2[3];
    }

    const brightness1 = getBrightness(color1);
    const brightness2 = getBrightness(color2);
    const contrastVal = Math.abs(brightness2 - brightness1);
    return contrastVal <= 15;
}

export function adjustContrast(color1, color2) {
    if (color1?.length === 4) {
        color1 = "#" + color1[1] + color1[1] + color1[2] + color1[2] + color1[3] + color1[3];
    }
    if (color2?.length === 4) {
        color2 = "#" + color2[1] + color2[1] + color2[2] + color2[2] + color2[3] + color2[3];
    }

    const brightness1 = getBrightness(color1);
    const brightness2 = getBrightness(color2);
    const contrastVal = Math.abs(brightness2 - brightness1);
    const contrastWithBlack = Math.abs(brightness2 - getBrightness("#000000"));
    const contrastWithWhite = Math.abs(brightness2 - getBrightness("#FFFFFF"));
    if (!color2) return color2;
    if (contrastVal <= 15) {
        const adjustedColor2 =
            contrastWithBlack > contrastWithWhite
                ? adjust(color2, -25)
                : adjust(color2, 25);
        return adjustedColor2;
    }

    return color2;
}

function getBrightness(hexColor) {
    if (!hexColor) return 0;

    hexColor = hexColor.replace("#", "");
    const bigint = parseInt(hexColor, 16);
    const R = (bigint >> 16) & 255;
    const G = (bigint >> 8) & 255;
    const B = bigint & 255;

    return R * 0.299 + G * 0.587 + B * 0.114;
}
