import { FETCH_SUCCESS, SHOULD_SCROLL, TABLE_UPDATE } from "../types";
import { tableUpdate } from "helpers/reducers";
import { AnyAction } from "redux";
import { IRestaurant } from "../interfaces";

function RestaurantsReducer(
    state: {
        id: string;
        restaurants: IRestaurant[];
        pagination: any;
        missing_restaurants: number[];
        scroll: boolean;
    } = {
        id: "Restaurants",
        restaurants: [],
        pagination: {},
        missing_restaurants: [],
        scroll: false,
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            const restaurantsData =
                state?.pagination &&
                state.pagination.page !==
                    action.response.data.meta.current_page &&
                action.response.data.meta.current_page !== 1
                    ? [...state.restaurants, ...action.response.data.data]
                    : action.response.data.data;
            const missingRestaurants =
                action.response.data.meta.options?.missing_restaurants?.map(
                    (item: any) => +item
                ) || [];

            return {
                ...state,
                restaurants: restaurantsData,
                missing_restaurants: missingRestaurants,
                pagination: {
                    ...state.pagination,
                    page: action.response.data.meta.current_page,
                    total: action.response.data.meta.total,
                    per_page: action.response.data.meta.per_page,
                    last_page: action.response.data.meta.last_page,
                    isLastPage: action.response.data.data.length === 0,
                },
            };
        case SHOULD_SCROLL:
            return {
                ...state,
                scroll: action.scroll,
            };
        case TABLE_UPDATE:
            return tableUpdate(state, action);

        default:
            return state;
    }
}

export default RestaurantsReducer;
