export const FETCH_SUCCESS = "RESTAURANTS/FETCH_SUCCESS";
export const TABLE_UPDATE = "RESTAURANTS/TABLE_UPDATE";
export const SHOULD_SCROLL = "SHOULD_SCROLL";

const types = {
    FETCH_FAILED: "RESTAURANTS/FETCH_FAILED",
    FETCH_REQUEST: "RESTAURANTS/FETCH_REQUEST",
    FETCH_SUCCESS,

    GET_FAILED: "RESTAURANTS/GET_FAILED",
    GET_REQUEST: "RESTAURANTS/GET_REQUEST",
    GET_SUCCESS: "RESTAURANTS/GET_SUCCESS",
    SHOULD_SCROLL,
    TABLE_UPDATE,
};

export default types;
