import moment from "moment";

export function timeRange(time: string) {
    if (!time) {
        return {
            timeFrom: "",
            timeTo: "",
        };
    }
    const parsedTime = moment(time as string, "HH:mm");
    const timeTo = parsedTime.clone().add(2, "hours").format("HH:mm");
    const timeFrom = parsedTime.clone().subtract(2, "hours").format("HH:mm");
    const maxTime = moment("23:59", "HH:mm");
    const minTime = moment("00:00", "HH:mm");
    let adjustedTimeFrom = moment.max(moment(timeFrom, "HH:mm"), minTime);
    let adjustedTimeTo = moment.min(moment(timeTo, "HH:mm"), maxTime);

    if (
        parsedTime.isAfter(adjustedTimeTo) 
    ) {
        adjustedTimeTo = moment("23:59", "HH:mm");
    }
    if (parsedTime.isBefore(adjustedTimeFrom)) {
        adjustedTimeFrom = moment("00:00", "HH:mm");
    }
    return {
        timeFrom: adjustedTimeFrom.format("HH:mm"),
        timeTo: adjustedTimeTo.format("HH:mm"),
    };
}
