let moduleConfig = {};

export function registerModule(name, config) {
    moduleConfig[name] = config;
}

export function getModule(name) {
    return moduleConfig[name];
}

export default moduleConfig;
