import { getColorByBgColor } from "helpers/colorShades";
import { FC } from "react";
import { IIcon } from "ui/types";

const ArrowLeftSolidIcon: FC<IIcon> = ({ color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
        >
            <path
                d="M3 18C3 9.7155 9.7155 3 18 3C26.2845 3 33 9.7155 33 18C33 26.2845 26.2845 33 18 33C9.7155 33 3 26.2845 3 18Z"
                fill={getColorByBgColor(color)}
            />
            <mask
                id="mask0_111_14468"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="2"
                width="32"
                height="32"
            >
                <path
                    d="M3 18C3 9.7155 9.7155 3 18 3C26.2845 3 33 9.7155 33 18C33 26.2845 26.2845 33 18 33C9.7155 33 3 26.2845 3 18Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.25 11.25L13.5 18L20.25 24.75"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </mask>
            <g mask="url(#mask0_111_14468)">
                <path d="M36 36L36 0L0 0L0 36L36 36Z" fill={color} />
            </g>
        </svg>
    );
};

export default ArrowLeftSolidIcon;
