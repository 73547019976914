import {
    FC,
    MouseEventHandler,
    ReactNode,
    // useContext,
    useEffect,
    useRef,
} from "react";
// import FormContext from "./FormContext";
import { Autocomplete } from "@react-google-maps/api";
import { IGPTextField } from "./TextField";

export interface IGPAutocompleteMap extends Omit<IGPTextField, ""> {
    errorText?: string;
    id: string;
    onChange?: Function;
    regex?: string;
    helperText?: string;
    value?: string;
    label?: string;
    type?: string;
    startAdornment?: ReactNode;
    placeholder?: string;
    fullWidth?: true;
    className?: any;
    onClick?: MouseEventHandler<HTMLInputElement>;
    ref?: any;
    latId: string;
    lngId: string;
}

const GPAutocompleteMap: FC<IGPAutocompleteMap> = (props) => {
    const {
        errorText,
        fullWidth,
        helperText,
        id,
        label,
        latId,
        lngId,
        onChange,
        regex,
        startAdornment,
        className,
        type,
        value,
        onClick,
        ...restProps
    } = props;
    // const context = useContext(FormContext);

    const autoCompleteRef = useRef<any>(null);
    const inputRef = useRef<any>(null);
    const options = {
        fields: ["address_components", "geometry", "name"],
        types: ['geocode']
        // language: document.documentElement.lang
    };

    useEffect(() => {
        if (!autoCompleteRef.current && window.google && window.google.maps) {
            autoCompleteRef.current =
                new window.google.maps.places.Autocomplete(
                    inputRef.current,
                    options
                );

            autoCompleteRef.current.addListener(
                "place_changed",
                handlePlaceChanged
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePlaceChanged = () => {
        const place = autoCompleteRef.current.getPlace();
        if (place && place.geometry) {
            // const lat = place.geometry.location.lat();
            // const lng = place.geometry.location.lng();
            // context?.onChange([
            //     { id: latId, value: lat },
            //     { id: lngId, value: lng },
            // ]);
        }
    };

    return (
        <div {...(className ? { className } : {})}>
            <div
                className={`relative rounded shadow-sm inline-flex items-center mt-0 bg-white ${
                    fullWidth ? "w-full" : "w-52"
                }`}
            >
                <span className="absolute left-0 h-6 ml-2 top-1/4">
                    {startAdornment && startAdornment}
                </span>
                <Autocomplete className="inline-flex items-center w-full mt-0 bg-white rounded shadow-sm">
                    <input
                        {...restProps}
                        type={type}
                        name={id}
                        id={id}
                        ref={inputRef}
                        className={`${
                            startAdornment ? "pl-10 " : "pl-2"
                        } block w-full h-12 rounded border-0 py-1.5 pr-4  text-Gray-70 placeholder:text-Gray-40 text-base leading-6 focus:outline-none`}
                    />
                </Autocomplete>
            </div>
        </div>
    );
};

GPAutocompleteMap.defaultProps = {
    errorText: "",
    helperText: "",
};

export default GPAutocompleteMap;
