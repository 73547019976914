import { FC } from "react";

const PhoneIcon: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                d="M6.6667 13.1465C9.2265 18.7209 13.7749 23.1391 19.4214 25.5358L20.328 25.9398C21.3321 26.3869 22.4617 26.4653 23.5178 26.1611C24.574 25.857 25.4889 25.1898 26.1014 24.2772L27.2867 22.5118C27.4722 22.235 27.5473 21.8987 27.4972 21.5693C27.4471 21.2398 27.2754 20.9411 27.016 20.7318L23 17.4918C22.8602 17.3791 22.699 17.2958 22.5261 17.2469C22.3532 17.198 22.1722 17.1845 21.994 17.2073C21.8158 17.2301 21.6441 17.2886 21.4891 17.3795C21.3341 17.4703 21.199 17.5915 21.092 17.7358L19.8494 19.4118C16.6595 17.8364 14.0775 15.254 12.5027 12.0638L14.1774 10.8212C14.3217 10.7142 14.4429 10.5791 14.5337 10.4241C14.6246 10.2691 14.6831 10.0974 14.7059 9.91917C14.7287 9.74097 14.7153 9.56 14.6664 9.38712C14.6175 9.21425 14.5341 9.05304 14.4214 8.91318L11.1814 4.89718C10.9722 4.63779 10.6734 4.46613 10.3439 4.41604C10.0145 4.36594 9.67821 4.44103 9.40136 4.62651L7.62403 5.81851C6.7059 6.43422 6.03615 7.35587 5.7341 8.41927C5.43205 9.48267 5.51731 10.6188 5.9747 11.6252L6.6667 13.1465Z"
                fill="#323947"
            />
        </svg>
    );
};

export default PhoneIcon;
