import { FC } from "react";

const MenuIcon: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                d="M22.0082 6.6665C19.8799 6.6665 17.588 7.11509 16.0055 8.34869C14.4229 7.11509 12.131 6.6665 10.0027 6.6665C8.42019 6.6665 6.73943 6.91322 5.33151 7.55246C4.53479 7.92254 4 8.71877 4 9.62715V22.2772C4 23.7351 5.33151 24.8117 6.70668 24.4528C7.77626 24.1725 8.91132 24.0491 10.0027 24.0491C11.7053 24.0491 13.5171 24.3407 14.9795 25.0808C15.6344 25.4173 16.3765 25.4173 17.0205 25.0808C18.4829 24.3295 20.2947 24.0491 21.9973 24.0491C23.0887 24.0491 24.2237 24.1725 25.2933 24.4528C26.6685 24.8229 28 23.7463 28 22.2772V9.62715C28 8.71877 27.4652 7.92254 26.6685 7.55246C25.2715 6.91322 23.5907 6.6665 22.0082 6.6665ZM25.8281 20.9427C25.8281 21.6492 25.1951 22.165 24.5184 22.0417C23.6999 21.8847 22.8486 21.8174 22.0082 21.8174C20.1528 21.8174 17.4789 22.5463 16.0055 23.4996V10.5916C17.4789 9.63837 20.1528 8.90942 22.0082 8.90942C23.0123 8.90942 24.0055 9.01035 24.955 9.22343C25.457 9.33557 25.8281 9.79537 25.8281 10.3225V20.9427Z"
                fill="#323947"
            />
            <path
                d="M19.4283 14.1859C19.1022 14.1859 18.8067 13.9673 18.7049 13.6176C18.5724 13.1913 18.7966 12.7214 19.1939 12.5903C20.7631 12.0438 22.7907 11.869 24.6553 12.0985C25.0731 12.1531 25.3787 12.5575 25.3278 13.0056C25.2769 13.4536 24.8999 13.7815 24.4821 13.7269C22.8315 13.5192 21.028 13.6831 19.6626 14.1531C19.5811 14.164 19.4996 14.1859 19.4283 14.1859ZM19.4283 17.0929C19.1022 17.0929 18.8067 16.8744 18.7049 16.5246C18.5724 16.0984 18.7966 15.6285 19.1939 15.4973C20.7529 14.9509 22.7907 14.776 24.6553 15.0055C25.0731 15.0602 25.3787 15.4645 25.3278 15.9126C25.2769 16.3607 24.8999 16.6886 24.4821 16.6339C22.8315 16.4263 21.028 16.5902 19.6626 17.0601C19.586 17.0811 19.5073 17.0921 19.4283 17.0929ZM19.4283 20C19.1022 20 18.8067 19.7814 18.7049 19.4317C18.5724 19.0055 18.7966 18.5355 19.1939 18.4044C20.7529 17.8579 22.7907 17.6831 24.6553 17.9126C25.0731 17.9672 25.3787 18.3716 25.3278 18.8197C25.2769 19.2678 24.8999 19.5847 24.4821 19.541C22.8315 19.3333 21.028 19.4973 19.6626 19.9672C19.586 19.9882 19.5073 19.9992 19.4283 20Z"
                fill="#323947"
            />
        </svg>
    );
};

export default MenuIcon;
