import { Spinner } from "@material-tailwind/react";


function Loader() {
    return (
        <div
            className={`flex flex-1 relative justify-center items-center bg-transparent h-full left-0 top-0 w-full z-100`}
        >
            <Spinner className="w-10 h-10" />
        </div>
    );
}
export default Loader;
