import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox, Form } from "ui";
import {
    IFilterForm,
    IPredefinedList,
    IPredefinedListFilters,
} from "../interfaces";
import { IGPFormRef } from "ui/Form/Form";
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
} from "@material-tailwind/react";
import { useSelector } from "react-redux";
import trans from "helpers/trans";
import { get, isEmpty, keys } from "lodash";

function AccordionIcon({ id, open }: { id: number; open: number | string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${
                id === open ? "rotate-180" : ""
            } h-5 w-5 transition-transform`}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
        </svg>
    );
}
const FilterForm: FC<IFilterForm> = ({
    data,
    errors,
    loading,
    onSubmit,
    refresh,
    submitForm,
}) => {
    const { filters } = useSelector((state: any) => state.app.settings.data);
    const [open, setOpen] = useState<number[]>([0, 1]);
    const formRef = useRef<IGPFormRef | null>();

    const predefinedList = useMemo(() => {
        const { cities, ...rest } = filters;
        return keys(rest)
            .filter((key) => !isEmpty(filters[key]))
            .map((key) => ({
                label: key,
                values: filters[key],
            }));
    }, [filters]);

    useEffect(() => {
        if (submitForm) {
            handleSubmit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitForm]);

    useEffect(() => {
        if (formRef.current && refresh !== 0) {
            formRef.current.refreshFormData({});
        }
    }, [refresh]);

    const handleChangeCheckbox = (
        e: any,
        context: any,
        id: string,
        idx: number,
        value: number
    ) => {
        if (e.value) {
            context.onChange([
                {
                    id: `${id}.${idx}`,
                    value: value,
                },
            ]);
        } else {
            context.onChange([
                {
                    id,
                    value: get(context.data, id, []).filter(
                        (item: number) => item !== value
                    ),
                },
            ]);
        }
    };

    const handleOpenAccordion = (value: number) => {
        if (open.includes(value)) {
            setOpen(open.filter((idx) => idx !== value));
        } else {
            setOpen([...open, value]);
        }
    };

    const handleSubmit = () => {
        if (formRef.current !== null) {
            const data: IPredefinedListFilters = formRef?.current?.getData();

            let filtersToReturn: IPredefinedListFilters = { _filters: {} };
            Object.keys(data._filters).forEach((filterType) => {
                filtersToReturn._filters[filterType] = data._filters[
                    filterType
                ].filter((item: number | undefined) => item);
            });

            onSubmit({
                _filters: filtersToReturn._filters,
            });
        }
    };

    return (
        <Form
            ref={formRef}
            data={data}
            errors={errors}
            fields={{
                _filters: {
                    hasChilds: true,
                    fields: {
                        accessibility_filter: { default: [] },
                        award_winning_filter: { default: [] },
                        cuisine_filter: { default: [] },
                        dishes_filter: { default: [] },
                        price_filter: { default: [] },
                        seating_options_filter: { default: [] },
                        type_filter: { default: [] },
                    },
                },
            }}
            loading={loading}
            onSubmit={() => {}}
            preventLeftForm
            unsaved={false}
        >
            {(context: any) => {
                return (
                    <div className="flex flex-col gap-2">
                        {predefinedList.map(
                            (item: IPredefinedList, idx: number) => {
                                return (
                                    <Accordion
                                        key={`accordion-${item.label}`}
                                        open={open.includes(idx) || !!data._filters[`${item.label}_filter`]?.length}
                                        icon={
                                            <AccordionIcon
                                                id={idx}
                                                open={
                                                    open.includes(idx) || !!data._filters[`${item.label}_filter`]?.length
                                                        ? idx
                                                        : ""
                                                }
                                            />
                                        }
                                    >
                                        <AccordionHeader
                                            onClick={() =>
                                                handleOpenAccordion(idx)
                                            }
                                            className="justify-start py-2 mb-1 text-xl font-bold border-b-0 !text-Gray-80 font-inter gap-1/4"
                                        >
                                            {trans(
                                                `restaurant.page.restaurantPage.${item.label}`
                                            )}
                                        </AccordionHeader>
                                        <AccordionBody className="grid grid-cols-1 py-0 text-base font-inter text-Gray-60 sm:grid sm:grid-cols-2 sm:gap-x-4">
                                            {item.values?.map(
                                                (val: number, idx: number) => {
                                                    return (
                                                        <Checkbox
                                                            className="h-auto text-Gray-60"
                                                            id={`_filters.${item.label.toLowerCase()}_filter.${idx}`}
                                                            key={`${item.label.toLowerCase()}_filter.${idx}`}
                                                            label={trans(
                                                                `booking.predefinedList.${val}`
                                                            )}
                                                            checked={get(
                                                                context.data,
                                                                `_filters.${item.label.toLowerCase()}_filter`,
                                                                []
                                                            ).includes(val)}
                                                            onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) =>
                                                                handleChangeCheckbox(
                                                                    e,
                                                                    context,
                                                                    `_filters.${item.label.toLowerCase()}_filter`,
                                                                    idx,
                                                                    val
                                                                )
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                        </AccordionBody>
                                    </Accordion>
                                );
                            }
                        )}
                    </div>
                );
            }}
        </Form>
    );
};
export default FilterForm;
