import { TrashIcon } from "@heroicons/react/24/outline";
import { modalHide } from "app/App/actions";
import { adjust, getColorByBgColor } from "helpers/colorShades";
import trans from "helpers/trans";
import { times } from "lodash";
import { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalContent, ModalTitle } from "ui";
import { getValue } from "ui/Form/helpers";

export interface IGeneralFiltersModal {
    context: any;
    id: string;
    filterName: string;
    content: any;
    onTimeChange?: Function;
    onPaxChange?: Function;
    onClose?: Function;
    openTime?: boolean;
}
const GeneralFiltersModal: FC<IGeneralFiltersModal> = ({
    context,
    id,
    filterName,
    content,
    onTimeChange,
    openTime,
}) => {
    const dispatch = useDispatch();
    const showOptions = useSelector((state: any) => state.app.showOptions);

    const { background, button_border_radius } = useSelector(
        (state: any) => state.app.settings.data
    );
    const buttonBorder = adjust(background, -66);
    const scrollToRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (openTime) {
            setTimeout(() => {
                if (scrollToRef.current) {
                    scrollToRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            }, 0);
        }
    }, [openTime]);

    const handleCloseModal = () => {
        dispatch(modalHide("GeneralFiltersModal"));
        if (showOptions) dispatch({ type: "SHOW_OPTIONS" });
    };
    const handleChange = (value: any) => {
        if (value === "") {
            context.onChange([
                {
                    id,
                    value,
                },
                {
                    id: "_filters.time_from_filter",
                    value,
                },
                {
                    id: "_filters.time_to_filter",
                    value,
                },
                {
                    id: "_filters.time_filter",
                    value,
                },
                {
                    id: "_filters.date_filter",
                    value,
                },
            ]);
        } else
            context.onChange([
                {
                    id,
                    value,
                },
            ]);
        dispatch(modalHide("GeneralFiltersModal"));
    };
    const renderContent = () => {
        if (filterName === "pax") {
            return (
                <div className="flex flex-wrap">
                    {content.map((item: number, idx: number) => {
                        const isActive =
                            +getValue(id, context, undefined) === item;
                        return (
                            <div className="w-1/3 p-1" key={`pax-${idx}`}>
                                <Button
                                    onClick={() => handleChange(item)}
                                    className={`text-ellipsis whitespace-nowrap overflow-hidden inline-block py-2 px-3 text-center w-full text-sm font-light duration-300 ease-in-out border-2 hover:shadow-none  shadow-none text-Gray-70  ${
                                        button_border_radius === "round"
                                            ? "rounded-5xl"
                                            : "rounded-none"
                                    }`}
                                    style={{
                                        color: isActive
                                            ? getColorByBgColor(buttonBorder)
                                            : "inherit",
                                        borderColor: buttonBorder,
                                        backgroundColor: isActive
                                            ? buttonBorder
                                            : "white",
                                    }}
                                >
                                    {idx === 0
                                        ? `${item} ${trans(
                                              "restaurant.modal.generalFiltersModal.person"
                                          )}`
                                        : `${item} ${trans(
                                              "restaurant.modal.generalFiltersModal.people"
                                          )}`}
                                </Button>
                            </div>
                        );
                    })}
                </div>
            );
        } else if (filterName === "time") {
            const minutes = content[0].split(":")[1];
            let contentArr = content;
            if (minutes !== "00") {
                const emptyToAdd = +minutes / 15;
                for (let i = 0; i < emptyToAdd; i++) {
                    contentArr = [...times(emptyToAdd, () => ""), ...content];
                }
            }
            const currentHourItems = !getValue(id, context, undefined)
                ? contentArr.findIndex((item: string) => {
                      const hour = parseInt(item.split(":")[0]);
                      return hour === 17;
                  })
                : contentArr.findIndex((item: string) =>
                      item.startsWith(
                          getValue(id, context, undefined).split(":")[0]
                      )
                  );
            return (
                <div className="flex flex-wrap">
                    {contentArr.map((item: number | string, idx: number) => {
                        const isActive =
                            getValue(id, context, undefined) === item;
                        const isRef = currentHourItems === idx;
                        return (
                            <div
                                className="w-1/4 p-1"
                                key={`time-${idx}`}
                                ref={isRef ? scrollToRef : null}
                            >
                                {item === "" ? (
                                    <div className="inline-block w-full h-full px-3 py-2"></div>
                                ) : (
                                    <Button
                                        onClick={() => onTimeChange?.(item)}
                                        className={`text-ellipsis whitespace-nowrap overflow-hidden inline-block py-2 px-3 text-center w-full text-sm font-light duration-300 ease-in-out border-2 hover:shadow-none  shadow-none text-Gray-70  ${
                                            button_border_radius === "round"
                                                ? "rounded-5xl"
                                                : "rounded-none"
                                        }`}
                                        style={{
                                            color: isActive
                                                ? getColorByBgColor(
                                                      buttonBorder
                                                  )
                                                : "inherit",
                                            borderColor: buttonBorder,
                                            backgroundColor: isActive
                                                ? buttonBorder
                                                : "white",
                                        }}
                                    >
                                        {item}
                                    </Button>
                                )}
                            </div>
                        );
                    })}
                </div>
            );
        }
    };
    return (
        <Modal
            open
            size="xxl"
            handler={() => {}}
            className="flex flex-col relative bg-white text-Gray-80 sm:rounded-md h-full sm:h-[530px] w-auto overflow-hidden !rounded-none"
        >
            <ModalTitle
                iconProps={{
                    color: "black",
                    height: 24,
                    width: 24,
                }}
                actions={
                    getValue(id, context, undefined) && (
                        <Button
                            variant="text"
                            className="p-0 rounded-full"
                            onClick={() => {
                                if (showOptions)
                                    dispatch({ type: "SHOW_OPTIONS" });
                                dispatch({
                                    type: "DELETE_FILTERS",
                                    payload: filterName,
                                });
                                dispatch(modalHide("GeneralFiltersModal"));
                                filterName === "pax"
                                    ? handleChange("")
                                    : onTimeChange?.("");
                            }}
                        >
                            <TrashIcon color="black" className="w-6 h-6 " />
                        </Button>
                    )
                }
                onClose={handleCloseModal}
                className="justify-center w-full px-5 py-2 text-xl font-medium leading-6 text-black md:text"
            >
                {trans(`restaurant.modal.generalFiltersModal.${filterName}`)}
            </ModalTitle>

            <ModalContent className="relative flex flex-col flex-1 px-5 py-2 overflow-y-auto max-h-[90vh]">
                {renderContent()}
            </ModalContent>
        </Modal>
    );
};

export default GeneralFiltersModal;
